.ContentCard{
    height: 600px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    .title {
        height: 150px;
        font-size: 25px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .body {
        overflow: auto;
        height: 150px;
        padding: 15px;
        white-space: pre-line;
        text-align: left;
    }
    
}

.UserCard{
    width: 165px;
    height: 225px;
    background: white;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
    margin-left: 20px;
}

.upper-container{
    height: 100px;
    background: linear-gradient(to bottom, #0E6DD2, #2B7FDD, #4992E9,#66A4F4,#83B6FF);

}

.upper-container-2 img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   
}

.userUpper-container{
    height: 70px;
    background:linear-gradient(to bottom, #0E6DD2, #2B7FDD, #4992E9,#66A4F4,#83B6FF);
}

.tag{
    display: flex;
    justify-content: center;
    transform: translate(5px, 35px);
}

.image-container img{
    background: white;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 2px;
    display: block;
    margin-left: auto;
    margin-right:auto;
    transform: translateY(45px);
}

.userImage-container img{
    background: white;
    width: 80px;
    height:80px;
    border-radius: 50%;
    padding: 2px;
    display: block;
    margin-left: auto;
    margin-right:auto;
    transform: translateY(20px);
}



#avatar-pic{
    border: 2px solid #83B6FF;
}

.lower-container{
    margin-top: 60px;
    height: 300px;
    background: white;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;

}

.lower-container p{
    margin:1rem;
    padding: 0;
}

.userLower-container{
    margin-top: 40px;
    height: 300px;
    background: white;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

button{
    margin: 1rem;
    margin-top: 0;
    color: #2f2d2e;
    font: bold;
    background-color: white;
    border: 2px solid #2B7FDD;
    padding: 0.6rem;
    border-radius: 6px;
    transition-duration: 0.5s;
    animation: ease-in-out
}
