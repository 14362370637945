@import "~react-image-gallery/styles/scss/image-gallery.scss";
$primary: #194F87;
$secondary: #FEFEFE;
$tertiary: #326396;
$my-lighten:#4080ff;
$default-font: sans-serif;
$serif-font: Palatino,Georgia,Cambria,"Times New Roman",Times,serif;
// Bootstrap: https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
// Might consider Ant-design breakpoints as well: https://ant.design/components/grid/#Col
$small-device-width: 576px;
$extra-large-device-width: 1200px;

.vh-100-min {
  min-height: 100vh !important;
}

body {
  margin: 0;
  font-family: $default-font,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
  font-family: $serif-font;
}

.container {
  width: 90%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-block-2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  text-align: center;
  color: inherit;
}

a {
  color: #0056b3;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link, .ant-btn-link {
  color: #0056b3;
}

.btn-link:hover, .ant-btn-link:hover {
  text-decoration: underline;
}

.max-width-30 {
  max-width: 30%;
}
.max-width-fit-content {
  max-width: fit-content;
}

.max-width-md-30 {
  h1 {
    font-size: 25px;
  }
}
@media (min-width: 768px) {
  .max-width-md-30 {
    max-width: 30%;
  }
  .max-width-md-23 {
    max-width: 23%;
    h1 {
      font-size: 34px;
    }
  }
}

.font-size-xl {
  font-size: x-large;
}
.font-size-40px {
  font-size: 40px;
}

.list-style-type-none li {
  list-style-type: none;
}

.chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0,0,0,.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-bottom: 5px;
  margin-right: 5px;
}

.chip > img {
  float: left;
  margin: 0 8px 0 -12px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.sans-{
  -webkit-font-smoothing: antialiased;
}

.cursor-pointer:hover {
  cursor: pointer;
}

label {
  color: #6c757d !important;
}

.Toastify__toast-body a {
  color: #b3d7ff;
  text-decoration: underline;
  font-weight: bold;
}

.btn-text {
  background-color: transparent;
  border: 1px solid transparent;
  // hover: #ffffff;
}

.ck-editor__editable {
  width: 100%;
}

.FormDynamicInput .ck.ck-editor__editable_inline, .ck.ck-editor__editable.ck-editor__editable_inline {
  overflow: auto;
  padding: 0 var(--ck-spacing-standard);
  border: 1px solid #40a9ff;
}

.input-error {
  box-shadow: 0 0 3px #CC0000;
  color: #CC0000;
  border: solid 1px #CC0000;
}

.ant-btn > a, .ant-btn > a:hover {
  color: currentColor !important;
  line-height: inherit !important;
}

.ant-btn {
  height: auto;
  white-space: pre-line;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.border-light {
  border: 1px solid #dee2e6 !important
}

@media only screen and (max-width: $small-device-width) {
  .border-sm-light {
    border: 1px solid #dee2e6 !important
  }
}

.font-size-larger {
  font-size: larger;
}

.btn-success {
  background: #52c41a;
  border: #52c41a;
  color: white;
}
.card-text {
  white-space: pre-line;
}

.square-icon {
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.object-fit-fill {
  object-fit: fill !important;
}

.ant-back-top {
  left: 100px;
}

@media screen and (max-width: 480px) {
  .ant-back-top {
    left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .ant-back-top {
    left: 60px;
  }

}

.button-cta {
  height: 45px;
  font-size: 20px;
}

.ant-select-multiple .ant-select-selection-item {
  height: auto;
}


// NOTION STYLES

.notion-h1 {
  text-align: left;
}

// CONTACT NETWORK GRAPH STYLES
.g-nodes {
  text {
    display: none;
  }
}

.g-nodes:hover {
  image{
    width: 50px;
    height: 50px
  }
  text {
    display: inline;
  }
}

.table-of-contents {
  max-height: 750px; 
  overflow:auto;
  // line-height and font-size borrowed from .ContentBody
  line-height: 2.5rem;
  font-size: 20px;
}

.shadow {
  &-top {
    box-shadow: 0px -15px 10px -15px #111;    
  }

  &-bottom {
    box-shadow: 0px 15px 10px -15px #111;    
  }

  &-bottom-inset {
    box-shadow: inset 0px -15px 10px -15px #111;
  }

  // not currently used, but in case it' needed, the logic is here
  &-bottom-inset-hover:hover {
    box-shadow: inset 0px -15px 10px -15px #111;
  }
  
  &-left {
    box-shadow: -15px 0px 10px -15px #111;    
  }
  &-right {
    box-shadow: 15px 0px 10px -15px #111;    
  }
}

.ScholarshipSponsorAgreement {
  li {
    margin-bottom: 10px;
    line-height: 30px
  }
}

.responsive-google-slides {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Ratio */
  height: 0;
  overflow: hidden;
}
.responsive-google-slides iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: white !important;
}
