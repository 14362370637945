@import "../../scenes/LandingPage/LandingPage";

$site-text-color: #314659;
footer.dark {
  background-color: #000;
  color: rgba(255, 255, 255, 0.65);
  a {
    color: rgba(255, 255, 255, 0.9);
  }
  h2 {
    color: rgba(255, 255, 255, 1);
    & > span {
      color: rgba(255, 255, 255, 1);
    }
  }
  .bottom-bar {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    overflow: hidden;
  }
}

footer {
  clear: both;
  font-size: 14px;
  background: #fff;
  position: relative;
  z-index: 100;
  color: $site-text-color;
  box-shadow: 0 1000px 0 1000px #fff;
  .ant-row {
    text-align: center;
    .footer-center {
      display: inline-block;
      text-align: left;
      > h2 {
        font-size: 16px;
        margin: 0 auto 24px;
        font-weight: 500;
        position: relative;

        > .title-icon {
          width: 27px;
          margin-right: 16px;
        }
        > .anticon {
          font-size: 16px;
          position: absolute;
          left: -22px;
          top: 3px;
          color: #aaa;
        }
      }
      > div {
        margin: 12px 0;
      }
    }
  }
  .footer-wrap {
    position: relative;
  }
  .bottom-bar {
    border-top: 1px solid $border-color;
    text-align: right;
    padding: 16px $padding-space;
    margin: 0;
    line-height: 32px;
    .translate-button {
      text-align: left;
    }
  }
}
.home{
  footer {
    .footer-wrap{
      width: 100%;
      padding: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      .ant-row{
        width: 100%;
        max-width: 1200px;
        padding: 86px 24px 93px 24px;
        margin: auto;
        
      }
    }
    .bottom-bar{
      margin: auto;
      max-width: 1200px;
      padding: 16px 24px;
    }
    .bottom-bar{
      border-top: none;
    }
  }
}