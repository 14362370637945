@import "../../index";

.EbookLandingBanner {
  h1,
  h2 {
    line-height: 3.5vw;
  }

  h1 {
    font-size: 5.5vw;
  }
  h2 {
    font-size: 2.5vw;
  }

  .buy-now-cta {
    margin-top: 10%;
  }

  .ebook-image img {
    max-width: 43vh;
  }

  @media only screen and (max-width: $small-device-width) {
    h1, h2 {
      line-height: 5.5vw;
    }

    h1 {
      font-size: 6.5vw;
      margin-top: 3%;
    }

    h2 {
      font-size: 4.5vw;
    }
    .ebook-row {
      margin-right: 1rem ;
      margin-left: 1rem ;
    }

    .buy-now-cta {
      margin-top: 3%;
    }

    .ebook-image img {
      max-width: 40vh;
    }

  }

  @media only screen and (min-width: $extra-large-device-width) {
    .ebook-row {
      margin-left: 20%;
    }
  }

  .buy-book-button {
    height: 100%;
    font-size: 30px;
  }
}

.InterviewCard {
  height: 725px;
  text-align: center;
  img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
  }
  @media only screen and (max-width: $small-device-width) {
    img {
      width: 200px;
      height: 200px;
    }
  }
}

.EbookPreview {
  .menu-item {
    padding: 10px 40px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: none;
  }

  .menu-wrapper {
      -moz-box-shadow:    0 0 10px #000000;
      -webkit-box-shadow: 0 0 10px #000000;
      box-shadow:         0 0 10px #000000;
  }

  @media only screen and (max-width: $small-device-width) {
    .menu-item-wrapper .responsive-images {
      width: 70%;
    }
    .menu-item {
      width: 425px;
      height: 450px;
      padding: 5px 10px;
      margin: 1px 5px;
      user-select: none;
      cursor: pointer;
      border: none;
    }
    .menu-wrapper {
      height: 500px;
    }
  }

  .scroll-menu-arrow {
    padding: 10px;
    cursor: pointer;
  }
}

.FAQ {
  li {
    margin: 0 0 10px 0;
  }
}

.chapter-name {
  text-align: left;
  padding-left: 20px;
}

.subchapter-item {
  text-align: left;
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.chapter-item {
  min-height: 400px;
}

.chapter-col {
  padding-left: 2%;
  padding-right: 2%;
}

#ebook-preview-embed {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
}

.PremiumDescription {
  font-size: larger;
}

.responsive-images {
  max-width: 90%;
  max-height: 750px;
  background-color: transparent;
  border: none;
}

.EbookPreview {
  @media only screen and (max-width: $small-device-width) {
    .image-gallery-right-nav, .image-gallery-left-nav {
      display: none;
    }
  }

}